var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "solutions-area section-width ptb-100 faq-contact-area",
      attrs: { id: "contactus" }
    },
    [
      _c(
        "div",
        { staticClass: "container wow fadeInUp" },
        [
          _c("div", { staticClass: "section-title" }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_4_4")))])
          ]),
          _c(
            "b-form",
            { attrs: { id: "contactForm" } },
            [
              _c("div", { staticClass: "text-center mb-4" }, [
                _c("span", { staticClass: "fa-stack fa-3x" }, [
                  _c("i", { staticClass: "fas fa-circle fa-stack-2x" }),
                  _c("i", {
                    staticClass: "fas fa-envelope fa-stack-1x fa-inverse"
                  })
                ])
              ]),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("form.item_1.label") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.$t("form.item_1.example"),
                      required: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("form.item_2.label") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.$t("form.item_2.example"),
                      type: "email",
                      required: "",
                      id: "email"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("form.item_3.label") } },
                [
                  _c("VuePhoneNumberInput", {
                    attrs: {
                      required: "",
                      translations: {
                        countrySelectorLabel: _vm.$t("cc"),
                        phoneNumberLabel: "0912345678",
                        example: _vm.$t("ex")
                      },
                      placeholder: _vm.$t("form.item_3.example")
                    },
                    on: { update: _vm.is_valid_phone },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formattedNumber,
                        expression: "formattedNumber"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "hidden", id: "phone" },
                    domProps: { value: _vm.formattedNumber },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.formattedNumber = $event.target.value
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("form.item_4.label") } },
                [
                  _c("b-form-input", {
                    attrs: { placeholder: _vm.$t("form.item_4.example") }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("form.item_5.label") } },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "textarea",
                      placeholder: _vm.$t("form.item_5.example"),
                      rows: "5",
                      "max-rows": "6"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "col-lg-12 col-md-12 mb-3" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-4" }),
                  _c("div", { staticClass: "col-lg-4" }),
                  _c("div", { staticClass: "col-lg-4" })
                ])
              ]),
              _c("div", { staticClass: "col-lg-12 col-md-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "default-btn page-btn btn btn-warning",
                    attrs: { type: "button", disabled: _vm.isSubmit },
                    on: { click: _vm.submitForm }
                  },
                  [
                    _vm.loadingVar ? _c("b-spinner") : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.$t("btn")) + " ")
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }